/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';
import { connect } from 'react-redux';
import EncodeUrl from '@/utils/encode';
import { stringify } from 'qs';

@connect(({ webs: { dataAd } }) => ({
  dataAd,
}))
class Index extends Component {
  state = {
    dataIntroduse1: [],
    dataIntroduse2: [],
    isInternational: '0'
  };

  componentDidMount() {
    const { isInternational } = this.state;
    this.fetch(isInternational);
  }

  fetch = (isInternational) => {
    const { data, dispatch } = this.props;
    this.setState({ loading: true })
    dispatch({
      type: 'webs/fetchProvinces',
      payload: {
        filter: {
          status: '1',
          categoriesId: data.id,
          categoriesProductsCatalogStatus: "1",
          // locations: { isInternational },
        }
      },
      callback: res => {
        this.setState({ loading: false })
        if (res.success) {
          if (res.result && res.result.list) {
            const dataRes = (res && res.result && res.result.list) || []
            this.setState({
              dataIntroduse1: dataRes && dataRes.length > 0 && dataRes.filter((item) => Number(item.countries.id) === 1) || [],
              dataIntroduse2: dataRes && dataRes.length > 0 && dataRes.filter((item) => Number(item.countries.id) !== 1) || [],
            });
          }
        }
      },
    });
  };

  handleChangeTabs = International => {
    const { isInternational } = this.state;
    if (isInternational !== International) {
      this.setState({
        isInternational: International,
      });
    }
  };

  handleProvince = (item) => {
    window.location.href = `/tim-kiem-dia-diem/${EncodeUrl(item.provinceName)}/${item.id}`;
  }

  render() {
    const { data } = this.props;
    const { dataIntroduse1, dataIntroduse2, isInternational } = this.state;
    const template =
      data.templateLayouts && data.templateLayouts.templates && data.templateLayouts.templates[0];
    const imageResize =
      template && template.templateLayoutTemplates && template.templateLayoutTemplates.imagesResize;
    const dataRender = isInternational === '0' ? dataIntroduse1 : dataIntroduse2
    // console.log(dataRender);
    return (
      // Tin tức trang chủ
      <React.Fragment>
        {/* dia diem moi nguoi yeu thich  */}
        <section className="diadiem">
          <div className="container">
            <div className="diadiem__wrapper">
              <div className="section-header-products">
                <h3 className="title">
                  <img src="/static/vietnamTickets/images/006-map.svg" alt="map" />
                  <span>{data.name}</span>
                </h3>
                <ul className='main-navigation'>
                  <li className={`tab ${isInternational === '0' && 'active'}`}>
                    <a onClick={() => this.handleChangeTabs('0')}>
                      <span>
                        <img src="/static/vietnamTickets/images/NoPath - Copy (10).png" alt="Nội địa" className='lazyload' />
                        Nội địa
                      </span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="202.068" viewBox="0 0 202.068 51.844">
                        <path id="Path_4163" data-name="Path 4163" d="M23289.355-14934.914s16.607-.354,22.189-13.742,10.816-37.814,43.607-37.934,70.2,0,70.2,0,26.232-1.732,36.559,18.467,7.742,28.393,29.514,33.324C23492.418-14934.945,23289.355-14934.914,23289.355-14934.914Z" transform="translate(-23289.355 14986.643)" fill="#eb0281" />
                      </svg>
                    </a>
                  </li>
                  <li className={`tab ${isInternational === '1' && 'active'}`}>
                    <a onClick={() => this.handleChangeTabs('1')}>
                      <span>
                        <img src="/static/vietnamTickets/images/earth.svg" alt="Nội địa" className='lazyload' />
                        Quốc tế
                      </span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="202.068" viewBox="0 0 202.068 51.844">
                        <path id="Path_4163" data-name="Path 4163" d="M23289.355-14934.914s16.607-.354,22.189-13.742,10.816-37.814,43.607-37.934,70.2,0,70.2,0,26.232-1.732,36.559,18.467,7.742,28.393,29.514,33.324C23492.418-14934.945,23289.355-14934.914,23289.355-14934.914Z" transform="translate(-23289.355 14986.643)" fill="#eb0281" />
                      </svg>
                    </a>
                  </li>
                  {/* etc. as many tabs as you'd like */}
                </ul>
              </div>
            </div>
          </div>
          <div className="diadiem_img hidden-m-t">
            <div className="container">
              <div className="diadiem_img-wrapper">
                {dataRender && dataRender.length > 0 && dataRender.slice(0, 9).map(item => {
                  return (
                    <div className="diadiem_img-wrapper__img"
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.handleProvince(item)}
                      key={item.id}
                    >
                      <img
                        src={getResponsiveImage(
                          item.images &&
                          item.images[0] &&
                          item.images[0].file,
                          imageResize
                        )}
                        className='lazyload'
                        alt={item.provinceName}
                      />
                      <div className="text">{item.provinceName}{isInternational !== '0' && ` - ${item.countries.countrieName}`}</div>
                    </div>
                  )
                })}

              </div>
            </div>
          </div>
          <div className="diadiem_img-mobile hidden-pc">
            {/* <div className="container"> */}
            <Row>
              <Col span={12}>
                {dataRender && dataRender.length > 0 && dataRender.map((item, index) => {
                  if (index % 2 === 0) {
                    return (
                      <div className="diadiem_img-wrapper__img"
                        onClick={() => this.handleProvince(item)}
                        key={item.id}
                      >
                        <img
                          src={getResponsiveImage(
                            item.images &&
                            item.images[0] &&
                            item.images[0].file,
                            imageResize
                          )}
                          className='lazyload'
                          alt={item.provinceName}
                        />
                        <div className="text">{item.provinceName}{isInternational !== '0' && ` - ${item.countries.countrieName}`}</div>
                      </div>
                    )
                  }
                  return null
                })}
              </Col>
              <Col span={12}>
                {dataRender && dataRender.length > 0 && dataRender.map((item, index) => {
                  if (index % 2 !== 0) {
                    return (
                      <div className="diadiem_img-wrapper__img"
                        onClick={() => this.handleProvince(item)}
                        key={item.id}
                      >
                        <img
                          src={getResponsiveImage(
                            item.images &&
                            item.images[0] &&
                            item.images[0].file,
                            imageResize
                          )}
                          className='lazyload'
                          alt={item.provinceName}
                        />
                        <div className="text">{item.provinceName}{isInternational !== '0' && ` - ${item.countries.countrieName}`}</div>
                      </div>
                    )
                  }
                  return null
                })}
              </Col>
            </Row>
            {/* </div> */}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Index;
